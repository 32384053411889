import styled from 'styled-components'
import { palette, theme } from 'styled-tools'

export const ListWrapper = styled.div`
  position: relative;
  max-width: ${theme('container.sizes.default')};
  padding-top: 7rem;
  background-color: ${palette('sand', 1)};
`

export const VerticalLine = styled.div`
  position: absolute;
  top: 0;
  left: 0.85rem;
  z-index: 2;
  width: 2px;
  height: 100%;
  background-color: ${palette('black')};

  ${theme('media.smUp')} {
    left: 50%;
    margin-left: 0.85rem;
  }
`

export const ListContainer = styled.ul`
  position: relative;
  z-index: 5;
  height: auto;
  margin: auto;
  background-color: ${palette('transparent')};

  & li:nth-child(odd) {
    ${theme('media.smUp')} {
      padding-left: 10%;
      text-align: right;
    }
  }
  & li:nth-child(even) {
    ${theme('media.smUp')} {
      margin-left: auto;
      padding-right: 10%;
      text-align: left;
    }
  }
`
