import * as React from 'react'

import styled from 'styled-components'
import theme from '../config/theme'
import Page from '../components/layouts/Page'
import Container from '../components/parts/Container'
import Heading from '../components/parts/Heading'
import Paragraph from '../components/parts/Paragraph'
import LineList from '../components/parts/LineList'
import LineListItem from '../components/parts/LineListItem'
import SectionCircularEconomy from '../components/parts/SectionCircularEconomy'
import CircularEconomyBg from '../components/parts/CircularEconomyBg'
import TextLink from '../components/parts/TextLink'
import Button from '../components/parts/Button'

const lineListContent = [
  {
    heading: 'Demontage',
    text:
      'Wir müssen uns erst einmal einen Überblick verschaffen. Dazu wird alles auseinandergenommen und auf Funktion und Beschädigung überprüft.',
  },
  {
    heading: 'Reinigung',
    text: 'Jetzt werden alle Bestandteile von Schmutz befreit und sorgfältig gereinigt. ',
  },
  {
    heading: 'Reparatur & Austausch',
    text:
      'Beschädigte Teile werden repariert oder ausgetauscht. Unser Anspruch ist es, dass es hinterher funktionell keinen Unterschied zu einem neuen Kinderwagen oder Kinderfahrrad gibt.',
  },
  {
    heading: 'Qualitätskontrolle',
    text:
      'Am Schluss erfolgt noch einmal eine Qualitätskontrolle, damit wir auch mit gutem Gewissen versprechen können, dass es egal ist, ob du ein neues oder gebrauchtes Produkt bei uns mietest.',
  },
  {
    heading: 'Verpackung',
    text:
      'Als letzter Schritt wird der Kinderwagen bzw. das Kinderfahrrad noch einmal auf Vollständigkeit geprüft. Dann bekommt es eine neue Verpackung und wird wieder in den Shop eingestellt.',
  },
]

const HeadingContainer = styled.div`
  padding-top: 5rem;
  padding-bottom: 2.5rem;
  background-color: ${theme.palette.yellow[1]};
`

const HeadingStyled = styled(Heading)`
  max-width: 36rem;
  margin-bottom: 2.5rem;
  font-weight: bold;
  font-size: 3rem;
  font-family: ${theme.fonts.libreBaskerville};
  line-height: 1.3;
  letter-spacing: 1.25px;
  & span {
    color: ${theme.palette.green[0]};
  }
  ${theme.media.smDown} {
    font-size: 2rem;
  }
`

const ParagraphStyled = styled(Paragraph)`
  max-width: 36rem;
  font-weight: 500;
  line-height: 1.5;
`

const LineListHeading = styled(Heading)`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 0.92;
`

const LineListParagraph = styled(Paragraph)`
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.4px;
`

const ListHeadingContainer = styled.div`
  max-width: 55rem;
  margin: 4rem auto 2rem;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 3rem auto;
`

const ListHeading = styled(Heading)`
  font-weight: bold;
  font-size: 3rem;
  font-family: ${theme.fonts.munkenSans};
  line-height: 1.2;
  letter-spacing: 1.25px;
  text-align: center;

  ${theme.media.smDown} {
    font-size: 2rem;
  }
`

const lineListItems = lineListContent.map((item) => (
  <LineListItem key={item.heading}>
    <LineListHeading level={3}>{item.heading}</LineListHeading>
    <LineListParagraph>{item.text}</LineListParagraph>
  </LineListItem>
))

const Refurbish = () => (
  <Page title="Refurbish" description="" backgroundColor={theme.palette.yellow[1]}>
    <HeadingContainer>
      <Container>
        <HeadingStyled level={1}>
          <span>Neu oder gebraucht?</span>
          <br />
          Egal. Hauptsache keine Kompromisse.
        </HeadingStyled>
        <ParagraphStyled>
          Der wichtigste Teil <TextLink to="/circular-economy">unserer Bewegung</TextLink> ist die
          sinnvolle und nachhaltige Nutzung unserer Ressourcen. Deshalb werden die Kinderwagen und
          Kinderräder, die zu uns zurückkommen, professionell aufbereitet und als
          &ldquo;Refurbished-Modelle&ldquo; zu einem reduzierten Preis angeboten. Das machen wir so
          lange, bis der Kinderwagen und das Kinderrad unseren hohen Qualitätsanforderungen nicht
          mehr entspricht und sie recycelt werden.
        </ParagraphStyled>
      </Container>
    </HeadingContainer>

    <Container>
      <ListHeadingContainer>
        <ListHeading level={2}>
          Schnell wieder im Einsatz:
          <br />
          Der Weg zum Refurbished-Modell
        </ListHeading>
      </ListHeadingContainer>
      <LineList>{lineListItems}</LineList>
      <ButtonContainer>
        <Button to="/produkte">
          Zu den Kinderwagen &amp; Fahrrädern
        </Button>
      </ButtonContainer>
    </Container>

    <CircularEconomyBg>
      <SectionCircularEconomy />
    </CircularEconomyBg>
    <div style={{ marginBottom: '4rem' }} />
  </Page>
)

export default Refurbish
