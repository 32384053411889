import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import { theme, palette, withProp } from 'styled-tools'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Pattern from '../Pattern'
import SvgPattern from '../../../static/images/patterns/one.svg'

export const Overlay = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: center;
  background-color: ${withProp(palette('black'), (color) => rgba(color, 0.5))};

  ${theme('media.smUp')} {
    min-height: 80vh;
    text-align: left;
  }
`

const CircularEconomyBg = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      stoneBg: file(relativePath: { eq: "strollMe_CircularEconomy-Teaser-1.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 2000) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  const { stoneBg } = data

  return (
    <BackgroundImage
      fluid={stoneBg.childImageSharp.fluid}
      style={{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
      }}
    >
      <Pattern top="-4rem" left="-6rem" width="80%" zIndex="0">
        <SvgPattern />
      </Pattern>
      <Overlay>{children}</Overlay>
    </BackgroundImage>
  )
}

CircularEconomyBg.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CircularEconomyBg
