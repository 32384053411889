import styled from 'styled-components'
import { palette, theme } from 'styled-tools'
import Container from '../Container'

import Heading from '../Heading'
import Paragraph from '../Paragraph'

export const ContainerStyled = styled(Container)`
  margin: 3rem auto;
  color: ${palette('white')};

  ${theme('media.smUp')} {
    align-self: flex-end;
    margin: 5rem auto;
  }
`

export const HeadingStyled = styled(Heading)`
  font-weight: bold;
  font-size: 3rem;
  font-family: ${theme('fonts.libreBaskerville')};
  line-height: 1.2;
  letter-spacing: 1.25px;

  ${theme('media.smDown')} {
    font-size: 2rem;
  }
`

export const ParagraphStyled = styled(Paragraph)`
  margin: 1.5rem 0 3rem 0;
  font-weight: bold;
  line-height: 1.5;
`
