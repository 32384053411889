import React from 'react'
import Button from '../Button'

import { ContainerStyled, ParagraphStyled, HeadingStyled } from './styled'

const SectionCircularEconomy = () => (
  <ContainerStyled>
    <HeadingStyled level={2}>Circular Economy.</HeadingStyled>
    <HeadingStyled level={2}>Wiederverwertung für die Erdbewohner von morgen.</HeadingStyled>

    <ParagraphStyled size="large">
      Für 700.000 neue Erdbewohner werden jährlich 800.000 neue Kinderwagen produziert. Allein in
      Deutschland. Wenn du auch findest, dass das nicht sein muss, nutze die Ressourcen schlauer.
      Mieten statt kaufen.
    </ParagraphStyled>

    <Button to="/circular-economy">Hier mehr darüber erfahren</Button>
  </ContainerStyled>
)

export default SectionCircularEconomy
