import React from 'react'
import PropTypes from 'prop-types'
import ListItem from './styled'
import GreenDotSvg from '../../../static/images/icons/green-dot.svg'

const LineListItem = ({ children }) => (
  <ListItem>
    <GreenDotSvg />
    {children}
  </ListItem>
)

LineListItem.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LineListItem
