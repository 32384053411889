import React from 'react'
import PropTypes from 'prop-types'
import { ListContainer, ListWrapper, VerticalLine } from './styled'

const LineList = ({ children }) => (
  <ListWrapper>
    <VerticalLine />
    <ListContainer>{children}</ListContainer>
  </ListWrapper>
)

LineList.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LineList
