import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { theme } from 'styled-tools'

const LinkStyled = styled((props) => <Link {...props} />)`
  color: inherit;
  font-size: 1rem;
  font-family: ${theme('fonts.munkenSans')};
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`

export default LinkStyled
