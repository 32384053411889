import React from 'react'
import PropTypes from 'prop-types'

import LinkStyled from './styled'

const TextLink = ({ children, ...props }) => <LinkStyled {...props}>{children}</LinkStyled>

TextLink.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TextLink
