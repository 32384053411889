import styled from 'styled-components'
import { theme } from 'styled-tools'

const ListItem = styled.li`
  width: 95%;
  margin-bottom: 5rem;
  padding-right: 1rem;
  padding-left: 5rem;
  word-break: break-word;
  ${theme('media.smUp')} {
    width: 45%;
    margin-left: 0;
    padding-left: unset;
  }

  & > svg {
    position: absolute;
    left: 0;
    margin-top: -0.25rem;
    ${theme('media.smUp')} {
      left: 50%;
    }
  }

  & > svg + * {
    margin-bottom: 1rem;
  }
`

export default ListItem
